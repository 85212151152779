<template>
  <div class="block-item d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <AvatarBox
        :imgUrl="imgUrl(data.image_path)"
        :imgAlt="data.nickname"
        imgSize="46px"
      />
      <span class="f-w3 _name">{{ data.nickname }}</span>
    </div>
    <button class="btn-release f-w3" @click="unBlock(data.user_id)">
      解除
    </button>
  </div>
</template>

<script>
import AvatarBox from "@/views/Common/AvatarBox.vue";
import { mapGetters } from "vuex";
import imageMixin from "@/mixins/imageMixin";

export default {
  name: "BlockItem",
  mixins: [imageMixin],
  components: { AvatarBox },
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      usersBlocked: "auth/usersBlock"
    })
  },
  methods: {
    unBlock(user_block_id) {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("auth/unblock", { user_block_id })
        .then(() => {
          this.$root.$refs.loading.finish();
          for (let i = 0; i < this.usersBlocked.length; i++) {
            if (user_block_id === this.usersBlocked[i].user_id) {
              this.usersBlocked.splice(this.usersBlocked[i], 1);
              this.$toast("ブロックを解除しました。", "通知", "success");
            }
          }
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          this.$toast("エラーが発生しました。", "通知", "danger");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.block-item {
  .avatar-box {
    margin-right: 11px;
  }
  ._name {
    text-align: left;
    word-wrap: break-word;
    width: 200px;
    font-size: 16px;
  }

  .btn-release {
    font-size: 14px;
    border-radius: 5px;
    width: 76px;
    height: 31px;
    background-color: #464d77;
    color: #fff;
  }
}

@media screen and (min-width: 1200px) {
  .block-item {
    .avatar-box {
      width: 80px !important;
      height: 80px !important;
      margin-right: 25px;
    }
    ._name {
      font-size: 18px;
      font-family: "Hiragino Kaku Gothic Pro W3"; // change font w3
      font-weight: bold;
      text-align: left;
      word-wrap: break-word;
      width: 250px;
    }

    .btn-release {
      width: 108px;
      height: 36px;
      font-size: 16px;
    }
  }
}
</style>
