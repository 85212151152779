<template>
  <div id="block" class="block">
    <HeaderCommon ref="header" title="ブロックリスト">
      <template v-slot:headerButton>
        <ButtonBack />
      </template>
    </HeaderCommon>
    <div id="content-scroll" class="content-scroll">
      <template v-if="usersBlocked.length > 0">
        <div class="block__list">
          <BlockItem
            v-for="(item, key) of usersBlocked"
            :key="key"
            :data="item"
            :imgAlt="true"
          />
        </div>
      </template>
      <template v-else>
        <p class="noti-message">現在ブロックしているゴルファーはいません。</p>
      </template>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import router from "@/router";
import HeaderCommon from "@/views/Common/HeaderCommon";
import CardFooter from "@/components/CardFooter";
import ButtonBack from "@/views/Common/ButtonBack.vue";
import BlockItem from "./BlockItem.vue";
import { mapGetters } from "vuex";

export default {
  name: "Blocked",
  components: {
    HeaderCommon,
    ButtonBack,
    BlockItem,
    CardFooter
  },
  created() {
    this.$store.dispatch("auth/getUsersBlock");
  },
  computed: {
    ...mapGetters({
      usersBlocked: "auth/usersBlock"
    })
  },
  methods: {
    nextPage() {
      router.push({ name: "MyPage" });
    }
  },
  metaInfo() {
    return {
      title: "ブロックリスト",
      titleTemplate: "%s | PreGo"
    };
  }
};
</script>

<style lang="scss" scoped>
.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 60px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

#block {
  // background-color: #f5f5f5;
  .header {
    .btn-back {
      position: absolute;
      left: 19px;
    }
  }
}

.block {
  &__list {
    padding: 28px 19px;
    max-width: 518px;
    margin: 0 auto;
    .block-item {
      margin-bottom: 15px;
    }
  }
  .noti-message {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    // height: calc(100vh - 152px);
    padding-top: 60px;
  }

  #block {
    .header {
      .btn-back {
        left: 35px;
      }
    }
  }
  .block {
    &__list {
      padding: 52px 0 30px;
      .block-item {
        margin-bottom: 25px;
      }
    }
    .noti-message {
      padding-top: 25px;
    }
  }
}
</style>
